import React, { useEffect }  from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import SEO from "../components/seo"

import AOS from 'aos';
import 'aos/dist/aos.css'

import FullWidthImage from "../components/FullWidthImage";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

import ContentLayout from "../components/ContentLayout";


// eslint-disable-next-line
export const ProductPageTemplate = ({
  image,
  title,
  heading,
  contentComponent,
  topContentText,
  topContentTagline,
  topContentImage,
  middleContentText,
  middleContentTagline,
  middleContentImage,
  bottomContentText,
  bottomContentTagline,
  bottomContentImage,
}) => {
  useEffect(() => {
    AOS.init();
  }, [])
  const heroImage = getImage(image) || image;

  return (
    <div className="content">
      <FullWidthImage img={heroImage} title={title} />
      <div>   
        <ContentLayout Image={topContentImage} Tagline={topContentTagline} Text={topContentText} imagePosition="right" />   
      </div>
      <div>   
        <ContentLayout Image={middleContentImage} Tagline={middleContentTagline} Text={middleContentText}  />   
      </div>
      <div>   
        <ContentLayout Image={bottomContentImage} Tagline={bottomContentTagline} Text={bottomContentText} imagePosition="right" />   
      </div>
    </div>
  );
};

ProductPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  topContentImage:  PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  topContentText:  PropTypes.string,
  topContentTagline:  PropTypes.string,
  middleContentImage:  PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  middleContentText:  PropTypes.string,
  middleContentTagline:  PropTypes.string,
  bottomContentImage:  PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  bottomContentText:  PropTypes.string,
  bottomContentTagline:  PropTypes.string,
};

const ProductPage = ({ data }) => {
  const { markdownRemark: post } = data;
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout>
      <SEO title={frontmatter.meta.title} description={frontmatter.meta.description} />
      <ProductPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        topContentImage={frontmatter.topContentImage}
        topContentText={frontmatter.topContentText}
        topContentTagline={frontmatter.topContentTagline}
        middleContentImage={frontmatter.middleContentImage}
        middleContentText={frontmatter.middleContentText}
        middleContentTagline={frontmatter.middleContentTagline}
        bottomContentImage={frontmatter.bottomContentImage}
        bottomContentText={frontmatter.bottomContentText}
        bottomContentTagline={frontmatter.bottomContentTagline}
      />
    </Layout>
  );
};

ProductPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ProductPage;

export const productPageQuery = graphql`
  query ProductPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        heading
        topContentText
        topContentTagline
        topContentImage {
          alt
          image {
            childImageSharp {
              gatsbyImageData(width: 526, quality: 92, layout: CONSTRAINED)
            }
          }
        }
        middleContentText
        middleContentTagline
        middleContentImage {
          alt
          image {
            childImageSharp {
              gatsbyImageData(width: 526, quality: 92, layout: CONSTRAINED)
            }
          }
        }
        bottomContentText
        bottomContentTagline
        bottomContentImage {
          alt
          image {
            childImageSharp {
              gatsbyImageData(width: 526, quality: 92, layout: CONSTRAINED)
            }
          }
        }
        meta {
          title
          description
        }
      }
    }
  }
`;
